import React from "react";

import Layout from "../layout/base";
import SEO from "../components/seo";
import Stage from "../components/stage";
import Breadcrumb from "../components/breadcrumb";
import JSON from "../../data/404.json";
const NotFoundPage = ({ i18n = "en" }) => (
  <Layout site="404" footer="" i18n={i18n}  url="404">
    <SEO
      title={JSON[i18n].meta.title}
      description={JSON[i18n].meta.description}
      lang={i18n}
    />
    <Stage className="mb-5 stage--404" site="404" i18n={i18n}>
      <div className="stage__title">
        <Breadcrumb data={JSON[i18n].breadcrumb} i18n={i18n} />
        <h1 dangerouslySetInnerHTML={{ __html: JSON[i18n].stage.title }}></h1>
        <p dangerouslySetInnerHTML={{ __html: JSON[i18n].stage.subtitle }}></p>
        {JSON[i18n].links.map((link, i) => {
          return (
            <div
              className="mb-2 util-text-1 "
              key={i}
              dangerouslySetInnerHTML={{ __html: link.link }}
            ></div>
          );
        })}
        <div
          className="mt-4 float-right"
          dangerouslySetInnerHTML={{ __html: JSON[i18n].button }}
        ></div>
      </div>
    </Stage>
  </Layout>
);

export default NotFoundPage;