import React from "react";

const Breadcrumb = (props) => {
  const content = props.data.map(function(site) {
    if (site.link) {
      return (
        <li key={site.text}>
          <a href={(props.i18n !== "en" ? "/" + props.i18n : "") + site.link}>{site.text}</a>
        </li>
      );
    } else {
      return <li key={site.text}>{site.text}</li>;
    }
  });

  return <ul className="breadcrumb">{content}</ul>;
};

export default Breadcrumb;
